import ApiService from './api.service'
const NewsService = {
  GetList(data) {
    return ApiService.post(`/News/GetList`, data);
  },
  Get(id) {
    return ApiService.get(`/News/Get/${id}`);
  },
  GetNewsImage(id) {
    return ApiService.get(`/News/GetNewsImage/${id}`);
  },
  GetListByTag(id, data) {
    return ApiService.post(`/News/GetListByTag/${id}`, data);
  },
  GetSecurityInfo() {
    return ApiService.get(`/News/GetSecurityInfo`);
  },
};
export default NewsService